import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function Filter(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 15.248 15.234" {...props}>
      <g transform="translate(-95.25 -229.418)">
        <path
          d="M95.26,231.494a.6.6,0,0,1,.634-.356c2.3.007,4.595,0,6.892,0h.187a2.244,2.244,0,0,1,4.366,0h.172c.828,0,1.657,0,2.486,0a.477.477,0,0,1,.487.34.439.439,0,0,1-.183.521.7.7,0,0,1-.336.088c-.819.007-1.637,0-2.456,0h-.173c-.014.038-.031.073-.041.109a2.212,2.212,0,0,1-4.268.013c-.034-.121-.1-.122-.193-.122-2.327,0-4.654,0-6.981,0a.562.562,0,0,1-.594-.366Zm9.9-1.135a1.265,1.265,0,0,0-.006,2.529,1.265,1.265,0,1,0,.006-2.529Z"
          transform="translate(-0.01 0)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M95.25,503.188a.558.558,0,0,1,.593-.367c2.323.007,4.645,0,6.968.006.121,0,.178-.027.214-.154a2.214,2.214,0,0,1,4.252,0l.043.15h.183c.839,0,1.678,0,2.516,0a.471.471,0,0,1,.181.909.784.784,0,0,1-.262.04c-.814,0-1.628,0-2.442,0h-.169c-.049.138-.087.276-.146.4a2.186,2.186,0,0,1-1.676,1.3.482.482,0,0,0-.07.024h-.6a.566.566,0,0,0-.081-.028,2.208,2.208,0,0,1-1.125-.566,2.251,2.251,0,0,1-.653-1.137h-.2c-2.3,0-4.6,0-6.9,0a.609.609,0,0,1-.634-.356C95.25,503.347,95.25,503.267,95.25,503.188Zm9.891,1.374a1.264,1.264,0,1,0-1.256-1.273A1.265,1.265,0,0,0,105.141,504.562Z"
          transform="translate(0 -260.857)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M95.26,367.169a.578.578,0,0,1,.614-.362c.8.012,1.6,0,2.4,0h.16a2.232,2.232,0,0,1,4.356,0h7.141a1.109,1.109,0,0,1,.178.006.476.476,0,0,1-.042.945c-.054,0-.109,0-.164,0h-7.122a2.239,2.239,0,0,1-.929,1.359,2.181,2.181,0,0,1-1.247.381,2.25,2.25,0,0,1-2.17-1.74H97.5c-.541,0-1.082-.01-1.623,0a.576.576,0,0,1-.615-.362Zm5.351,1.382a1.263,1.263,0,1,0-1.269-1.265A1.263,1.263,0,0,0,100.611,368.551Z"
          transform="translate(-0.01 -130.257)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
}
