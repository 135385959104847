import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function List(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 15.484 11.24" {...props}>
      <g
        id="Group_1583"
        data-name="Group 1583"
        transform="translate(12587 5594)"
      >
        <g
          id="Group_1568"
          data-name="Group 1568"
          transform="translate(-12587 -5594)"
        >
          <circle
            id="Ellipse_72"
            data-name="Ellipse 72"
            cx="1"
            cy="1"
            r="1"
            fill={theme.palette.primary.main}
          />
          <path
            id="Line_213"
            data-name="Line 213"
            d="M9.323,1.065H.147A1.094,1.094,0,0,1-1,.032,1.094,1.094,0,0,1,.147-1H9.323A1.094,1.094,0,0,1,10.47.032,1.094,1.094,0,0,1,9.323,1.065Z"
            transform="translate(5.014 1)"
            fill={theme.palette.primary.main}
          />
        </g>
        <g
          id="Group_1572"
          data-name="Group 1572"
          transform="translate(-12587 -5589.412)"
        >
          <circle
            id="Ellipse_72-2"
            data-name="Ellipse 72"
            cx="1"
            cy="1"
            r="1"
            transform="translate(0 0.412)"
            fill={theme.palette.primary.main}
          />
          <path
            id="Line_213-2"
            data-name="Line 213"
            d="M9.323,1.065H.147A1.094,1.094,0,0,1-1,.032,1.094,1.094,0,0,1,.147-1H9.323A1.094,1.094,0,0,1,10.47.032,1.094,1.094,0,0,1,9.323,1.065Z"
            transform="translate(5.014 1)"
            fill={theme.palette.primary.main}
          />
        </g>
        <g
          id="Group_1573"
          data-name="Group 1573"
          transform="translate(-12587 -5584.824)"
        >
          <circle
            id="Ellipse_72-3"
            data-name="Ellipse 72"
            cx="1"
            cy="1"
            r="1"
            transform="translate(0 -0.176)"
            fill={theme.palette.primary.main}
          />
          <path
            id="Line_213-3"
            data-name="Line 213"
            d="M9.323,1.065H.147A1.094,1.094,0,0,1-1,.032,1.094,1.094,0,0,1,.147-1H9.323A1.094,1.094,0,0,1,10.47.032,1.094,1.094,0,0,1,9.323,1.065Z"
            transform="translate(5.014 1)"
            fill={theme.palette.primary.main}
          />
        </g>
      </g>
    </SvgIcon>
  );
}
