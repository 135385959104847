import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Impressions(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="15.351" viewBox="0 0 16 15.351" {...props}>
      <path
        id="Path_307"
        data-name="Path 307"
        d="M119.186,235.769c.068-.406.136-.815.207-1.224.154-.9.307-1.806.468-2.709a.384.384,0,0,0-.126-.383q-1.35-1.305-2.689-2.622a1.283,1.283,0,0,1-.221-1.6,1.258,1.258,0,0,1,.933-.619c1.248-.184,2.5-.368,3.747-.544a.351.351,0,0,0,.3-.221q.827-1.692,1.665-3.38a1.272,1.272,0,0,1,1.9-.5,1.56,1.56,0,0,1,.444.537c.562,1.1,1.106,2.217,1.649,3.33a.376.376,0,0,0,.325.234q1.857.261,3.712.541a1.239,1.239,0,0,1,1.061.874,1.225,1.225,0,0,1-.324,1.336q-1.317,1.3-2.654,2.584a.493.493,0,0,0-.164.5c.216,1.2.418,2.408.623,3.612a1.3,1.3,0,0,1-1.921,1.377c-1.086-.572-2.174-1.14-3.257-1.717a.422.422,0,0,0-.449,0q-1.647.879-3.3,1.739a1.288,1.288,0,0,1-1.856-.762A3.75,3.75,0,0,1,119.186,235.769Zm1.126-.031c-.012.176.12.266.292.19.037-.016.072-.036.108-.055,1.081-.568,2.164-1.133,3.242-1.706a1.382,1.382,0,0,1,1.384.007q1.64.873,3.289,1.727a.3.3,0,0,0,.248.016.284.284,0,0,0,.074-.238c-.2-1.211-.4-2.422-.622-3.629a1.433,1.433,0,0,1,.443-1.393c.883-.846,1.755-1.7,2.626-2.561.062-.061.132-.185.108-.243a.338.338,0,0,0-.236-.154c-1.139-.172-2.278-.358-3.422-.491a1.652,1.652,0,0,1-1.458-1.036c-.382-.857-.823-1.688-1.239-2.529-.112-.227-.22-.457-.34-.68-.091-.168-.251-.166-.349,0-.024.039-.043.082-.063.123-.537,1.09-1.079,2.178-1.61,3.271a1.39,1.39,0,0,1-1.139.814q-1.823.253-3.643.531a.339.339,0,0,0-.236.154c-.024.057.048.179.109.242.24.249.493.485.741.727.64.624,1.277,1.252,1.921,1.872a1.339,1.339,0,0,1,.423,1.254c-.118.7-.241,1.4-.361,2.1C120.506,234.6,120.41,235.167,120.312,235.738Z"
        transform="translate(-116.639 -221.722)"
        fill="#ec1d23"
      />
    </SvgIcon>
  );
}
