import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from '../../endpoints';
import pvtFetcher from '../../utils/pvtFetcher';
import { IndividualUserAnalyticsData } from '../types';

export default function useIndividualUserAnalyticsData({
  userId,
  authToken,
  orgId,
  queryConfig,
}: {
  userId: string;
  authToken: string;
  orgId: number;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = API_ENDPOINTS.GET_INDIVIDUAL_USER_ANALYTICS(userId);

  return useQuery<IndividualUserAnalyticsData, Error>(
    [url, { authToken, orgId }],
    () => pvtFetcher(url, { method: 'GET', authToken, orgId }),
    queryConfig
  );
}
