import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function Clock(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 16 15.968" {...props}>
      <g id="clock" transform="translate(0 -0.001)">
        <path
          id="Path_300"
          data-name="Path 300"
          d="M89.911,223.94h.938a.879.879,0,0,0,.121.026,7.622,7.622,0,0,1,3.49,1.1,7.881,7.881,0,0,1,3.8,5.486c.056.3.084.6.125.906v.936c-.013.112-.026.225-.038.337a8.02,8.02,0,0,1-7.626,7.169,7.618,7.618,0,0,1-4.412-1.113,7.867,7.867,0,0,1-3.8-5.487c-.056-.3-.084-.6-.125-.906v-.936q.019-.168.038-.337A8.015,8.015,0,0,1,89,224.064C89.3,224.011,89.608,223.981,89.911,223.94Zm.476,14.729a6.744,6.744,0,1,0-6.765-6.733A6.756,6.756,0,0,0,90.387,238.669Z"
          transform="translate(-82.38 -223.94)"
          fill={theme.palette.primary.main}
        />
        <path
          id="Path_301"
          data-name="Path 301"
          d="M271.216,320.691c0,.466,0,.931,0,1.4a.554.554,0,0,0,.245.487c.178.127.351.261.526.393.445.333.888.667,1.334,1a.482.482,0,1,0,.577-.77c-.53-.4-1.061-.8-1.6-1.194a.26.26,0,0,1-.117-.24c0-.814,0-1.628,0-2.441a.5.5,0,0,0-.317-.509.484.484,0,0,0-.651.484C271.214,319.76,271.216,320.226,271.216,320.691Z"
          transform="translate(-263.345 -314.467)"
          fill={theme.palette.primary.main}
        />
        <path
          id="Path_302"
          data-name="Path 302"
          d="M271.219,320.69c0-.466,0-.931,0-1.4a.484.484,0,0,1,.651-.484.5.5,0,0,1,.317.509c0,.814,0,1.628,0,2.441a.259.259,0,0,0,.117.24q.8.591,1.6,1.194a.482.482,0,1,1-.577.77c-.446-.33-.889-.665-1.334-1-.175-.131-.348-.266-.526-.393a.554.554,0,0,1-.245-.487C271.22,321.622,271.219,321.156,271.219,320.69Z"
          transform="translate(-263.347 -314.466)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
}
