import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F8F8F8',
      padding: theme.spacing(3),
      alignItems: 'center',
    },
    contentCtn: {
      maxWidth: theme.custom.container1460,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
    title: {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: theme.palette.gray.contrastText,
    },
    user: {
      display: 'flex',
      gap: theme.spacing(0.5),
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(2),
      },
    },
    userId: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.up('md')]: {
        fontSize: 32,
      },
    },
    userType: {
      fontSize: 12,
      fontWeight: 600,
      borderRadius: '50%',
      backgroundColor: 'white',
      width: 25,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        width: 76,
        height: 76,
        fontSize: 35,
      },
    },
    cardCtn: {
      display: 'flex',
      gap: theme.spacing(2),
      flexDirection: 'column',
    },
    card: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3, 1.5),
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
      height: '100%',
    },
    tag: {
      marginLeft: 'auto',
      backgroundColor: '#99EAAC',
      borderRadius: 8,
      fontWeight: 600,
      padding: theme.spacing(0.5, 1.5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    summaryCtn: {
      order: 1,
    },
    audienceProfileCtn: {
      order: 2,
    },
    convertChanceCtn: {
      order: 3,
      [theme.breakpoints.up('md')]: {
        order: 4,
      },
    },
    timelineCtn: {
      order: 4,
      [theme.breakpoints.up('md')]: {
        order: 3,
      },
    },
  })
);

export default useStyles;
