import { createStyles, makeStyles, alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleCtn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
    },
    label: {
      backgroundColor: alpha('#EC1D23', 0.21),
      padding: theme.spacing(0.5, 1.25),
      borderRadius: '0.5rem',
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    logo: {
      backgroundColor: theme.palette.gray.light2,
      width: 31,
      height: 31,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      '& > svg': {
        fontSize: '1rem',
      },
    },
  })
);

export default useStyles;
