import * as React from 'react';
import { Router } from '@reach/router';
import { AnalyticsUserPage } from '../../../../components/pages/my-account/analytics/user/AnalyticsUserPage';

export default function MyAccountAnalyticsUserPage() {
  return (
    <Router basepath="/my-account/analytics/user">
      <AnalyticsUserPage path="/:userId" />
    </Router>
  );
}
