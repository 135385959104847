import { Box } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { Typography } from '../../atoms';
import useStyles from './AudienceProfile.styles';
import { AudienceProfileData } from './types';

export interface AudienceProfileProps {
  data: AudienceProfileData[];
}

const palette = ['#EC1D23', '#3258A8', '#B9CFFD'];
const graphicSize = 135;

export default function AudienceProfile({ data }: AudienceProfileProps) {
  const classes = useStyles();
  const sortedData = React.useMemo(
    () => data.sort((a, b) => b.value - a.value).slice(0, 3),
    [JSON.stringify(data)]
  );
  const [firstValue, secondValue, thirdValue] = sortedData;
  const secondGraphicSize =
    (graphicSize * secondValue.value) / firstValue.value;
  const thirdGraphicSize = (graphicSize * thirdValue.value) / firstValue.value;

  return (
    <Box>
      <Box className={classes.graphicCtn}>
        <Box
          className={clsx(classes.graphic, classes.mainGraphic)}
          bgcolor={palette[0]}
          width={graphicSize}
          height={graphicSize}
        >
          {firstValue.value}%
          {Boolean(secondValue.value) && (
            <Box
              className={clsx(
                classes.graphic,
                classes.secondGraphic,
                firstValue.value - secondValue.value < 20 && 'equal'
              )}
              bgcolor={palette[1]}
              width={secondGraphicSize}
              height={secondGraphicSize}
            >
              {secondGraphicSize > 20 && `${secondValue.value}%`}
            </Box>
          )}
          {Boolean(thirdValue.value) && (
            <Box
              className={clsx(
                classes.graphic,
                classes.thirdGraphic,
                firstValue.value - thirdValue.value < 20 && 'equal'
              )}
              bgcolor={palette[2]}
              width={thirdGraphicSize}
              height={thirdGraphicSize}
            >
              {thirdGraphicSize > 20 && `${thirdValue.value}%`}
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" bgcolor="#e3e3e3" height={6} mb={5.5}>
        {sortedData.map((point, i) => (
          <Box key={point.label} width={`${point.value}%`}>
            <Box bgcolor={palette[i]} height={6} mb={1} />
            <Typography
              color="gray"
              colorVariant="contrastText"
              fontSize={10}
              textAlign="center"
            >
              {point.label}
              <br />
              {point.value}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
