import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function ListView(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 16.743 16.739" {...props}>
      <g id="website" transform="translate(-133.655 -229.585)">
        <path
          d="M134.8,235.114v.187q0,4.056,0,8.111a1.706,1.706,0,0,0,.889,1.594,1.553,1.553,0,0,0,.79.179c.654,0,1.308,0,1.961,0a.561.561,0,0,1,.6.588.519.519,0,0,1-.5.533,20.062,20.062,0,0,1-2.667-.056,2.646,2.646,0,0,1-2.184-2.366,4.547,4.547,0,0,1-.027-.462q0-5.464,0-10.928a2.823,2.823,0,0,1,1.27-2.474,2.543,2.543,0,0,1,1.463-.435q5.508,0,11.017,0a3.025,3.025,0,0,1,2.942,2.387,2.729,2.729,0,0,1,.045.514c0,1.551,0,3.1,0,4.653a.632.632,0,0,1-.4.634.657.657,0,0,1-.73-.116.588.588,0,0,1-.211-.489c0-.618,0-1.236,0-1.854v-.2Zm-.027-1.161h14.289c0-.512.007-1.015,0-1.518a1.689,1.689,0,0,0-1.741-1.708H136.483a1.56,1.56,0,0,0-1.612,1.284A16.1,16.1,0,0,0,134.77,233.953Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M243.916,410.533a5.758,5.758,0,0,1-5.3-3.073,11.717,11.717,0,0,1-.574-1.261.325.325,0,0,1,0-.234,6.2,6.2,0,0,1,5.061-3.869,6.294,6.294,0,0,1,6.6,3.771.539.539,0,0,1,.02.425,5.858,5.858,0,0,1-4.845,4.143C244.515,410.5,244.139,410.512,243.916,410.533Zm.026-7.34a5.138,5.138,0,0,0-4.6,2.928.309.309,0,0,0,0,.315,5.1,5.1,0,0,0,9.188.008.308.308,0,0,0,.009-.315A5.131,5.131,0,0,0,243.942,403.194Z"
          transform="translate(-99.395 -164.241)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M311.662,278.837a.67.67,0,1,1,.714-.672A.665.665,0,0,1,311.662,278.837Z"
          transform="translate(-168.859 -45.633)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M356.311,278.838a.67.67,0,1,1,.7-.66A.652.652,0,0,1,356.311,278.838Z"
          transform="translate(-211.381 -45.633)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M398.353,278.847a.669.669,0,1,1,.7-.661A.653.653,0,0,1,398.353,278.847Z"
          transform="translate(-251.419 -45.642)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M314.129,445.3a2.288,2.288,0,1,1,2.3,2.279A2.3,2.3,0,0,1,314.129,445.3Zm2.29,1.1a1.107,1.107,0,1,0-1.11-1.109A1.112,1.112,0,0,0,316.419,446.394Z"
          transform="translate(-171.877 -203.249)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
}
