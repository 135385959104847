import { Box } from '@material-ui/core';
import { TimelineItem, TimelineSeparator } from '@material-ui/lab';
import { format, formatDistanceToNow } from 'date-fns';
import * as React from 'react';
import { Typography } from '../../atoms';
import useStyles, {
  StyledTimeline,
  StyledTimelineConnector,
  StyledTimelineContent,
  StyledTimelineDot,
  StyledTimelineOppositeContent,
} from './AnalyticUserTimeline.styles';
import { AnalyticUserTimelineData, AnalyticUserTimelineType } from './types';

const AnalyticUserTimelineDataPalette = {
  [AnalyticUserTimelineType.CONVERSION]: '#99EAAC',
  [AnalyticUserTimelineType.VIEW]: '#B9CFFD',
  [AnalyticUserTimelineType.INTERACTION]: '#FC9927',
};

export interface AnalyticUserTimelineProps {
  data: AnalyticUserTimelineData[];
}

export default function AnalyticUserTimeline({
  data,
}: AnalyticUserTimelineProps) {
  const classes = useStyles();

  return (
    <StyledTimeline>
      {data.map(({ date, text, type }, index) => (
        <TimelineItem key={index}>
          <StyledTimelineOppositeContent>
            <Typography variant="subtitle2" color="gray" colorVariant="dark">
              {formatDistanceToNow(date, { addSuffix: true })}
            </Typography>
            <Typography variant="subtitle2" className={classes.gray}>
              {format(date, 'd MMM hh:mma')}
            </Typography>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineDot />
            {index !== data.length - 1 && <StyledTimelineConnector />}
          </TimelineSeparator>
          <StyledTimelineContent>
            <Box
              className={classes.tag}
              bgcolor={AnalyticUserTimelineDataPalette[type]}
            >
              <Typography
                variant="subtitle2"
                className={classes.gray}
                fontWeight={600}
              >
                {type}
              </Typography>
            </Box>
            <Typography variant="subtitle1" className={classes.gray}>
              {text}
            </Typography>
          </StyledTimelineContent>
        </TimelineItem>
      ))}
    </StyledTimeline>
  );
}
