import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

const AverageTime = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 21 21.023" {...props}>
      <g transform="translate(-105.893 -223.521)">
        <path
          d="M123.053,225.9c0-.463-.007-.917,0-1.371a.952.952,0,0,1,1.163-.964.929.929,0,0,1,.742.892q.012,1.946,0,3.893a.911.911,0,0,1-.9.907q-1.955.017-3.91,0a.946.946,0,0,1,0-1.891c.512-.013,1.024,0,1.536,0l.045-.062a1.226,1.226,0,0,1-.144-.084,8.585,8.585,0,1,0-7.075,15.2,13.494,13.494,0,0,0,1.881.212.984.984,0,0,1,.924.793.945.945,0,0,1-.623,1.062,1.207,1.207,0,0,1-.372.059,10.492,10.492,0,0,1-3.719-20.279,10.22,10.22,0,0,1,10.232,1.513l.161.12A.276.276,0,0,0,123.053,225.9Z"
          transform="translate(0 0)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M265.663,291.627c0-.98-.007-1.961,0-2.941a.934.934,0,0,1,.813-.916.947.947,0,0,1,1.063.678,1.632,1.632,0,0,1,.041.425q0,2.5,0,5a.529.529,0,0,0,.175.413q1.214,1.2,2.415,2.415a.961.961,0,0,1-.379,1.633.893.893,0,0,1-.924-.214q-1.487-1.472-2.959-2.96a.944.944,0,0,1-.249-.705c0-.943,0-1.885,0-2.828Z"
          transform="translate(-150.249 -60.444)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M380.351,497.305a.965.965,0,0,1-1.931-.015.965.965,0,1,1,1.931.015Z"
          transform="translate(-256.292 -256.551)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M327.625,531.29a.965.965,0,1,1,0,1.93.965.965,0,0,1,0-1.93Z"
          transform="translate(-207.587 -289.403)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M426.3,383.68a.961.961,0,1,1-.973.95A.96.96,0,0,1,426.3,383.68Z"
          transform="translate(-300.366 -150.596)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M414.294,446.911a.961.961,0,1,1,.962-.963A.954.954,0,0,1,414.294,446.911Z"
          transform="translate(-289.092 -208.247)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};

export default AverageTime;
