import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function PreviousConversion(props: SvgIconProps) {
  return (
    <SvgIcon
      width="10.861"
      height="15.566"
      viewBox="0 0 10.861 15.566"
      {...props}
    >
      <g id="transfer" transform="translate(-202.135 -284.348)">
        <path
          id="Path_314"
          data-name="Path 314"
          d="M204.818,294.3a1.928,1.928,0,0,1,.163-.225q2.46-2.463,4.922-4.923c.336-.336.54-.337.876,0,.2.206.4.419.6.628l.055-.027c.192-1.463.385-2.925.582-4.423l-4.474.589c.259.255.486.477.709.7a.473.473,0,0,1,0,.772q-1.595,1.6-3.192,3.191a2.049,2.049,0,0,1-.206.156c.337.334.647.625.934.937a.775.775,0,0,1,.193.4.389.389,0,0,1-.268.4.423.423,0,0,1-.491-.09c-.251-.241-.494-.489-.741-.735-.171-.17-.344-.339-.512-.512a.467.467,0,0,1,.006-.756q1.6-1.6,3.207-3.205a1.448,1.448,0,0,1,.209-.142c-.387-.383-.721-.721-1.062-1.052a.474.474,0,0,1,.282-.865q2.183-.283,4.365-.57c.485-.064.97-.13,1.454-.2a.462.462,0,0,1,.568.528q-.384,2.943-.774,5.884a.466.466,0,0,1-.845.269c-.338-.342-.68-.68-1.067-1.067a1.6,1.6,0,0,1-.155.221q-2.459,2.464-4.922,4.923c-.338.338-.544.34-.877,0-.2-.206-.4-.418-.648-.675-.2,1.529-.394,2.991-.592,4.493l4.423-.581.068-.082a1.2,1.2,0,0,1-.236-.142c-.932-.9-.917-.837,0-1.749.958-.949,1.908-1.9,2.884-2.88l-.771-.766c-.076-.075-.154-.148-.222-.229a.452.452,0,0,1,.638-.639c.164.146.314.308.47.463q.377.376.752.753c.308.311.309.527,0,.833l-3.332,3.333c.038.046.071.093.111.133.305.307.61.614.919.918a.477.477,0,0,1,.166.514.47.47,0,0,1-.431.325q-1.687.221-3.373.446c-.815.108-1.631.21-2.446.32-.419.057-.65-.166-.594-.591q.382-2.911.764-5.821a.473.473,0,0,1,.319-.434.465.465,0,0,1,.514.159Z"
          transform="translate(0 0)"
          fill="#ec1d23"
        />
      </g>
    </SvgIcon>
  );
}
