export enum AnalyticUserTimelineType {
  CONVERSION = 'Conversion',
  VIEW = 'View',
  INTERACTION = 'Interaction',
}

export interface AnalyticUserTimelineData {
  type: AnalyticUserTimelineType;
  date: Date;
  text: React.ReactNode;
}
