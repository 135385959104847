import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    logo: {
      backgroundColor: theme.palette.gray.light2,
      width: 31,
      height: 31,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      '& > svg': {
        fontSize: '1rem',
      },
    },
  })
);

export default useStyles;
