import { AnalyticSummaryCardProps } from '../../../../../organisms/AnalyticSummaryCard';
import ViewsIcon from '../../../../../../images/icons/Views';
import AverageTimeIcon from '../../../../../../images/icons/AverageTime';
import EmailIcon from '../../../../../../images/icons/Email';
import PhoneCallIcon from '../../../../../../images/icons/PhoneCall';
import ListIcon from '../../../../../../images/icons/List';
import ClockIcon from '../../../../../../images/icons/Clock';
import ListViewIcon from '../../../../../../images/icons/ListView';
import CursorIcon from '../../../../../../images/icons/Cursor';
import FilterIcon from '../../../../../../images/icons/Filter';
import ActivityLevelIcon from '../../../../../../images/icons/ActivityLevel';
import { AnalyticUserTimelineType } from '../../../../../organisms/AnalyticUserTimeline';
import * as React from 'react';
import { add, formatDistance } from 'date-fns';
import { IndividualUserAnalyticsData } from '../../../../../../api/analytics/types';
import { formatDurationToString } from '../../../../../../utils/date';

export const summaryCardProps = (
  data?: IndividualUserAnalyticsData['data']
): AnalyticSummaryCardProps[] => [
  {
    icon: ListIcon,
    title: `${data?.visitCount ?? '-'} visits`,
    subtitle: 'in total',
  },
  {
    icon: ViewsIcon,
    title: data?.lastVisit
      ? formatDistance(new Date(data.lastVisit), new Date(), {
          addSuffix: true,
        })
      : '-',
    subtitle: 'Last visit',
  },
  {
    icon: AverageTimeIcon,
    title: data?.averageEngagementTime.website
      ? formatDurationToString(
          Math.round(data?.averageEngagementTime.website),
          {}
        )
      : '-',
    subtitle: 'Spent on website',
  },
  {
    icon: ClockIcon,
    title: data?.averageEngagementTime.properties
      ? formatDurationToString(
          Math.round(data?.averageEngagementTime.properties),
          {}
        )
      : '-',
    subtitle: 'Average time/Development',
  },
  {
    icon: ListViewIcon,
    title: `${data?.developmentsViewed.total ?? '-'} listings viewed`,
    subtitle: `${
      data?.developmentsViewed.underOurManagement ?? '-'
    } under your management`,
  },
  {
    icon: CursorIcon,
    title: `${data?.interactionsCount ?? '-'} interactions`,
  },
  {
    icon: EmailIcon,
    title: `${data?.enquiriesCount.total ?? '-'} emails`,
    subtitle: `${data?.enquiriesCount.toUs ?? '-'} to your team`,
  },
  {
    icon: PhoneCallIcon,
    title: `${data?.callsCount.total ?? '-'} calls`,
    subtitle: `${data?.callsCount.toUs ?? '-'} to your team`,
  },
  {
    icon: FilterIcon,
    title: '4 filter(s)',
    subtitle: 'London, 2-bedroom, < £2,000, Pet friendly ',
  },
  {
    icon: ActivityLevelIcon,
    title: 'Medium',
    subtitle: 'Activity level',
  },
];

export const audienceProfileData = [
  {
    label: 'Young',
    value: 76,
  },
  {
    label: 'DIY',
    value: 13,
  },
  {
    label: 'Family',
    value: 6,
  },
  {
    label: 'Others',
    value: 5,
  },
];

export const analyticUserTimelineData = [
  {
    date: new Date(),
    text: (
      <span>
        The user converted on <b>3 bedroom apartment in Hamme</b> after 2 visits
        and 2 days elapsed.
      </span>
    ),
    type: AnalyticUserTimelineType.CONVERSION,
  },
  {
    date: add(new Date(), { days: -1 }),
    text: (
      <span>
        User visited the listing <b>3 bedroom apartment in Hamme</b> for 1
        minute 9 seconds.
      </span>
    ),
    type: AnalyticUserTimelineType.VIEW,
  },
  {
    date: add(new Date(), { days: -2 }),
    text: (
      <span>
        User clicked on a link or button inside the blog
        <b>/15-reasons-to-sell -your-home-today.</b> This action redirected them
        away from the article.
      </span>
    ),
    type: AnalyticUserTimelineType.INTERACTION,
  },
  {
    date: add(new Date(), { days: -3 }),
    text: (
      <span>
        User visited the listing 3 bedroom appartment in Hamme for 1 minute 9
        seconds.
      </span>
    ),
    type: AnalyticUserTimelineType.VIEW,
  },
];
