import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    graphicCtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(6),
    },
    graphic: {
      color: 'white',
      borderRadius: '50%',
      fontSize: 15,
      fontWeight: 600,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mainGraphic: {
      position: 'relative',
    },
    secondGraphic: {
      position: 'absolute',
      fontSize: 13,
      left: 0,
      transform: 'translate(-50%, -10px)',
      '&.equal': {
        transform: 'translate(-65%, 0)',
      },
    },
    thirdGraphic: {
      position: 'absolute',
      fontSize: 13,
      right: '12.5%',
      bottom: '12.5%',
      transform: 'translate(25%, 25%)',
      '&.equal': {
        right: 0,
        bottom: 'unset',
        transform: 'translate(65%, 0)',
      },
    },
    equal: {
      transform: 'translate(-50%, -10px)',
    },
  })
);

export default useStyles;
