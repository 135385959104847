import { Box, Grid } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import useMyUserDetails from '../../../../../../api/auth/me/useMyUserDetails';
import { SEO, Typography } from '../../../../../atoms';
import { GenericPage, Layout } from '../../../../../layout';
import { ColorLabel } from '../../../../../molecules';
import AnalyticSummaryCard from '../../../../../organisms/AnalyticSummaryCard';
import AnalyticUserTimeline from '../../../../../organisms/AnalyticUserTimeline';
import AudienceProfile from '../../../../../organisms/AudienceProfile';
import ConvertChanceChart from '../../../../../organisms/ConvertChanceChart';
import useStyles from './AnalyticsUserPage.styles';
import {
  summaryCardProps,
  audienceProfileData,
  analyticUserTimelineData,
} from './config';
import useAuthToken from '../../../../../../store/auth/hooks/useAuthToken';
import useIndividualUserAnalyticsData from '../../../../../../api/analytics/useIndividualUserAnalyticsData/useIndividualUserAnalyticsData';

export interface AnalyticsUserPageProps {
  userId: string;
}

export default function AnalyticsUserPage({
  userId,
}: RouteComponentProps<AnalyticsUserPageProps>) {
  const classes = useStyles();
  const authToken = useAuthToken();
  const { data: userDetailsData } = useMyUserDetails({
    authToken,
  });
  const { data: analyticsData } = useIndividualUserAnalyticsData({
    userId: userId || '',
    orgId: userDetailsData?.organization.organizationId || 0,
    authToken,
    queryConfig: {
      enabled:
        userDetailsData?.organization.organizationId != null && userId != null,
    },
  });

  return (
    <Layout>
      <SEO title="Analytics User" />
      <GenericPage>
        <Box className={classes.ctn}>
          <Box className={classes.contentCtn}>
            <Box className={classes.user}>
              <Box className={classes.userType}>EE</Box>
              <Typography
                className={classes.userId}
                color="primary"
                fontWeight="bold"
              >
                {userId}
              </Typography>
              <Box className={classes.tag}>Active</Box>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} className={classes.summaryCtn}>
                <Box className={classes.cardCtn}>
                  <ColorLabel className={classes.title}>Summary</ColorLabel>
                  <Grid container spacing={2} alignItems="stretch">
                    {summaryCardProps(analyticsData?.data).map((val, idx) => (
                      <Grid item xs={6} md={3} key={idx}>
                        <Box className={classes.card}>
                          <AnalyticSummaryCard {...val} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} className={classes.audienceProfileCtn}>
                <Box className={classes.cardCtn}>
                  <ColorLabel className={classes.title}>
                    Audience profile
                  </ColorLabel>
                  <Box className={classes.card}>
                    <AudienceProfile data={audienceProfileData} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} className={classes.convertChanceCtn}>
                <Box className={classes.cardCtn}>
                  <ColorLabel className={classes.title}>
                    Chance to convert
                  </ColorLabel>
                  <Box className={classes.card}>
                    <ConvertChanceChart
                      conversionChance={76}
                      conversionChanceChange={-4}
                      impressionsCount={106}
                      listingsViewedCount={12}
                      actionsCount={11}
                      previousConversionsCount={0}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} className={classes.timelineCtn}>
                <Box className={classes.cardCtn}>
                  <ColorLabel className={classes.title}>Timeline</ColorLabel>
                  <Box className={classes.card}>
                    <AnalyticUserTimeline data={analyticUserTimelineData} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </GenericPage>
    </Layout>
  );
}
