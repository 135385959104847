import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function Cursor(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 10.976 16" {...props}>
      <g id="action" transform="translate(-237.399 -375.598)">
        <path
          id="Path_312"
          data-name="Path 312"
          d="M245.179,384.553c.933,1.42,1.829,2.783,2.723,4.147.415.633.352.906-.292,1.279q-1.23.712-2.464,1.421c-.59.338-.871.257-1.173-.345-.746-1.48-1.488-2.963-2.258-4.5l-1.2.99c-.344.285-.678.582-1.031.856a.624.624,0,0,1-1.083-.45c-.227-2.543-.442-5.087-.661-7.631q-.154-1.792-.308-3.586c-.012-.139-.017-.279-.031-.417-.03-.3.032-.609.333-.693a1.01,1.01,0,0,1,.726.1c.662.412,1.291.878,1.932,1.325q3.743,2.608,7.486,5.215c.272.189.537.378.484.761s-.361.5-.67.608C246.874,383.931,246.053,384.233,245.179,384.553Zm-6.418-7.112c.266,3.125.526,6.161.792,9.276.676-.564,1.279-1.079,1.893-1.578a.626.626,0,0,1,1.066.214c.153.281.289.572.433.858l1.953,3.88,1.752-1.013c-.972-1.477-1.916-2.908-2.854-4.341-.365-.556-.238-.93.384-1.161.713-.264,1.428-.523,2.221-.812Z"
          transform="translate(0 0)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
}
