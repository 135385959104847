import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function ViewedListings(props: SvgIconProps) {
  return (
    <SvgIcon
      width="14.565"
      height="14.568"
      viewBox="0 0 14.565 14.568"
      {...props}
    >
      <g id="menu" transform="translate(-130.475 -267.675)">
        <path
          id="Path_308"
          data-name="Path 308"
          d="M130.487,271.111c0-.533,0-1.065,0-1.6a1.771,1.771,0,0,1,1.836-1.836q1.606,0,3.212,0a1.776,1.776,0,0,1,1.843,1.849q0,1.6,0,3.2a1.786,1.786,0,0,1-1.848,1.849q-1.606,0-3.212,0a1.778,1.778,0,0,1-1.831-1.827C130.484,272.2,130.487,271.656,130.487,271.111Zm3.452,1.957c.532,0,1.065,0,1.6,0,.243,0,.34-.1.341-.339q0-1.606,0-3.211c0-.241-.1-.345-.339-.345q-1.605,0-3.211,0c-.237,0-.345.107-.345.344q0,1.606,0,3.211c0,.239.1.34.344.341C132.863,273.069,133.4,273.067,133.939,273.068Z"
          transform="translate(-0.011)"
          fill="#ec1d23"
        />
        <path
          id="Path_309"
          data-name="Path 309"
          d="M133.914,447.033c-.533,0-1.065,0-1.6,0a1.771,1.771,0,0,1-1.839-1.833q0-1.607,0-3.213a1.776,1.776,0,0,1,1.845-1.847q1.6,0,3.2,0a1.785,1.785,0,0,1,1.851,1.845q0,1.607,0,3.213a1.776,1.776,0,0,1-1.822,1.835C135,447.036,134.458,447.033,133.914,447.033Zm.014-5.39c-.538,0-1.076,0-1.614,0-.239,0-.342.1-.343.342q0,1.606,0,3.211c0,.236.109.343.346.343q1.605,0,3.211,0c.238,0,.338-.105.338-.347q0-1.606,0-3.212c0-.242-.1-.337-.342-.338C134.992,441.641,134.459,441.642,133.927,441.642Z"
          transform="translate(0 -164.791)"
          fill="#ec1d23"
        />
        <path
          id="Path_310"
          data-name="Path 310"
          d="M306.353,267.7c.532,0,1.065,0,1.6,0a1.77,1.77,0,0,1,1.836,1.836q0,1.607,0,3.213a1.777,1.777,0,0,1-1.849,1.844q-1.6,0-3.2,0a1.787,1.787,0,0,1-1.848-1.848q0-1.607,0-3.213a1.776,1.776,0,0,1,1.826-1.832C305.265,267.7,305.809,267.7,306.353,267.7Zm-1.956,3.452c0,.533,0,1.065,0,1.6,0,.243.1.341.338.341h3.211c.241,0,.346-.1.346-.339q0-1.606,0-3.211c0-.237-.107-.345-.343-.346q-1.605,0-3.211,0c-.239,0-.34.1-.341.343C304.4,270.075,304.4,270.613,304.4,271.151Z"
          transform="translate(-164.749 -0.022)"
          fill="#ec1d23"
        />
        <path
          id="Path_311"
          data-name="Path 311"
          d="M309.794,443.6c0,.532,0,1.065,0,1.6a1.77,1.77,0,0,1-1.836,1.835q-1.606,0-3.212,0a1.778,1.778,0,0,1-1.843-1.85q0-1.6,0-3.2a1.786,1.786,0,0,1,1.849-1.848q1.606,0,3.212,0a1.775,1.775,0,0,1,1.83,1.826C309.8,442.516,309.794,443.06,309.794,443.6Zm-3.437-1.956h-1.6c-.265,0-.355.091-.355.359q0,1.588,0,3.177c0,.262.1.36.359.361h3.176c.257,0,.36-.105.36-.365q0-1.588,0-3.177c0-.261-.1-.355-.365-.355C307.41,441.647,306.883,441.648,306.357,441.648Z"
          transform="translate(-164.756 -164.797)"
          fill="#ec1d23"
        />
      </g>
    </SvgIcon>
  );
}
