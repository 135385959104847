import { createStyles, makeStyles, styled } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab';

export const StyledTimelineDot = styled(TimelineDot)(() => ({
  boxShadow: 'none',
  backgroundColor: '#EBEAEA',
}));

export const StyledTimeline = styled(Timeline)(() => ({
  margin: 0,
  padding: 0,
}));

export const StyledTimelineConnector = styled(TimelineConnector)(
  ({ theme }) => ({
    margin: theme.spacing(-1, 0),
    backgroundColor: '#EBEAEA',
  })
);

export const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  padding: theme.spacing(0, 1, 1.125, 1),
  flex: 3,
}));

export const StyledTimelineOppositeContent = styled(TimelineOppositeContent)(
  ({ theme }) => ({
    padding: theme.spacing(0, 1, 1.125, 1),
  })
);

const useStyles = makeStyles((theme) =>
  createStyles({
    gray: {
      color: '#1D1D1D',
    },
    tag: {
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(1),
      width: 'fit-content',
    },
  })
);

export default useStyles;
