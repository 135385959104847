import * as React from 'react';
import { Box, SvgIconProps } from '@material-ui/core';
import { Typography } from '../../atoms';
import useStyles from './AnalyticSummaryCard.styles';

export interface AnalyticSummaryCardProps {
  icon: React.ElementType<SvgIconProps>;
  title: React.ReactNode;
  subtitle?: string;
}

export default function AnalyticSummaryCard({
  icon: Icon,
  title,
  subtitle,
}: AnalyticSummaryCardProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Box className={classes.logo}>
        <Icon />
      </Box>
      <Typography
        fontSize={14}
        fontWeight={600}
        color="gray"
        colorVariant="contrastText"
      >
        {title}
      </Typography>
      <Typography fontSize={12} color="gray" colorVariant="contrastText">
        {subtitle}
      </Typography>
    </Box>
  );
}
