import { Box, Grid, useTheme, alpha } from '@material-ui/core';
import * as React from 'react';
import Impressions from '../../../images/icons/Impressions';
import ViewedListings from '../../../images/icons/ViewedListings';
import Actions from '../../../images/icons/Actions';
import PreviousConversion from '../../../images/icons/PreviousConversion';
import { Typography } from '../../atoms';
import useStyles from './ConvertChanceChart.styles';

export interface ConvertChanceChartProps {
  conversionChance: number;
  conversionChanceChange: number;
  impressionsCount: number;
  listingsViewedCount: number;
  actionsCount: number;
  previousConversionsCount: number;
}

const circlePerimeter = 62 * Math.PI * 2;
const centroid = 70;
const strokeWidth = 8;
const circleRadius = centroid - strokeWidth;

export default function ConvertChanceChart({
  conversionChance,
  conversionChanceChange,
  impressionsCount,
  listingsViewedCount,
  actionsCount,
  previousConversionsCount,
}: ConvertChanceChartProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.ctn}>
      <Box>
        <svg width={centroid * 2} height={centroid * 2}>
          <circle
            cx={centroid}
            cy={centroid}
            r={circleRadius}
            fill="none"
            stroke={alpha(theme.palette.primary.main, 0.2)}
            strokeWidth={strokeWidth}
          />
          <circle
            cx={centroid}
            cy={centroid}
            r={circleRadius}
            fill="none"
            stroke="#F85358"
            strokeWidth={8}
            strokeDasharray={`${
              (conversionChance / 100) * circlePerimeter
            }, ${circlePerimeter}`}
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={24}
            fontWeight={600}
          >
            {conversionChance}%
          </text>
        </svg>
      </Box>
      <Box className={classes.titleCtn}>
        <Typography fontSize={12} textAlign="center">
          Based on the user’s recent actions on our site, we were able to
          calculate the likeliness this user will convert.
        </Typography>
        <Box className={classes.label}>
          <Typography fontSize={14} fontWeight={600} textAlign="center">
            {conversionChanceChange}% AVG
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.gridItem}>
          <Box className={classes.logo}>
            <Impressions />
          </Box>
          <Box>
            <Typography fontSize={12} fontWeight={600}>
              {impressionsCount}
            </Typography>
            <Typography fontSize={12} color="grey">
              Impressions
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <Box className={classes.logo}>
            <ViewedListings />
          </Box>
          <Box>
            <Typography fontSize={12} fontWeight={600}>
              {listingsViewedCount}
            </Typography>
            <Typography fontSize={12} color="grey">
              Viewed listings
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <Box className={classes.logo}>
            <Actions />
          </Box>
          <Box>
            <Typography fontSize={12} fontWeight={600}>
              {actionsCount}
            </Typography>
            <Typography fontSize={12} color="grey">
              Actions
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <Box className={classes.logo}>
            <PreviousConversion />
          </Box>
          <Box>
            <Typography fontSize={12} fontWeight={600}>
              {previousConversionsCount}
            </Typography>
            <Typography fontSize={12} color="grey">
              Previous conversion
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
