import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function ActivityLevel(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 16 13.708" {...props}>
      <g transform="translate(-121.568 -273.063)">
        <path
          d="M125.768,273.788l.037,0c.015.048.032.095.046.142l2.229,7.73q.319,1.106.639,2.211a.613.613,0,0,0,.714.49.645.645,0,0,0,.522-.565q.878-3.685,1.761-7.368c.08-.333.162-.666.243-1a.352.352,0,0,1,.088.151c.3.9.6,1.805.906,2.707a.606.606,0,0,0,.628.458q.673,0,1.346,0a.613.613,0,1,0,0-1.225c-.247,0-.494-.011-.74,0a.219.219,0,0,1-.26-.183q-.716-2.177-1.446-4.351a.618.618,0,0,0-1.214.048q-.824,3.43-1.645,6.86c-.112.467-.225.935-.346,1.439-.028-.09-.045-.143-.06-.2q-1.441-4.986-2.882-9.971a.619.619,0,0,0-1.222.029q-.959,4.072-1.916,8.143c-.018.074-.038.147-.067.262-.037-.092-.058-.141-.077-.191q-.516-1.376-1.031-2.752a.606.606,0,0,0-.563-.456c-.437-.014-.875-.019-1.311,0a.6.6,0,0,0-.574.626.62.62,0,0,0,.628.6c.213,0,.427.009.639,0,.124-.007.171.042.212.153q.8,2.156,1.612,4.31a.619.619,0,0,0,1.212-.069q.807-3.417,1.611-6.835Z"
          transform="translate(2 2.396)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
}
